import { HttpService } from '../../http/http.service';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { AuthenticationService } from '../authentication/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../../http/http.service";
import * as i2 from "../authentication/authentication.service";
export const API_URL = environment.BASE_URL;
export class UsuarioService {
    constructor(httpClient, authenticationService) {
        this.httpClient = httpClient;
        this.authenticationService = authenticationService;
        this.subirAvatar = function (avatar) {
            return this.httpClient.post('usuarios/subir-avatar', { avatar });
        };
        this.listadoUsuarios = function (filtro) {
            return this.httpClient.get('usuarios/', filtro);
        };
    }
    obtenerUsuarios(count, page, orderby, order, filters, search) {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('orderby', orderby)
            .set('order', order)
            .set('search', search);
        if (count) {
            params = params.set('count', count.toString());
        }
        params = this.httpClient.agregarFiltrosParams(filters, params);
        return this.httpClient.get('usuarios', params);
    }
    obtenerFiltrosUsuarios(columna) {
        return this.httpClient.get(`usuarios/filtros/${columna}`);
    }
    modificarUsuario(idUsuario, datosUsuario) {
        return this.httpClient.put(`usuarios/${idUsuario}`, datosUsuario);
    }
    obtenerRolesDisponibles() {
        return this.httpClient.get(`usuarios/roles`);
    }
    crearUsuario(dataUsuario) {
        return this.httpClient.post(`usuarios`, dataUsuario);
    }
    /*
    public borrarUsuario(usuarioId) {
      return this.httpClient.delete(`usuarios/${usuarioId}`);
    }
    */
    obtenerUsuario(usuarioId) {
        return forkJoin([
            this.httpClient.get(`usuarios/${usuarioId}`),
            this.httpClient.get(`usuarios/roles`),
        ]);
    }
    recoverPassword(email) {
        return this.httpClient.post(`usuarios/recoverPass`, { 'email': email });
    }
    resetPassword(email, clave) {
        return this.httpClient.post(`usuarios/resetPass`, { 'email': email, 'clave': clave });
    }
    obtenerSupervisores() {
        return this.httpClient.get(`usuarios/supervisores`);
    }
    obtenerRegadores() {
        return this.httpClient.get(`usuarios/regadores`);
    }
    obtenerSupervisoresUsuario(usuarioId) {
        return this.httpClient.get(`usuarios/${usuarioId}/supervisor`);
    }
    quitarSupervisor(usuarioId, relacionId) {
        return this.httpClient.delete(`usuarios/${usuarioId}/supervisor/${relacionId}`);
    }
    asignarSupervisor(usuarioId, supervisorId) {
        return this.httpClient.post(`usuarios/${usuarioId}/supervisor`, { supervisorId });
    }
    switchearRolFcoria() {
        return this.httpClient.put(`usuarios/fcoria/rol`, {});
    }
    getUserFullName() {
        return this.authenticationService.user.nombre;
    }
    /**
     * ?obtener el valor de usuario de la BD
     */
    getUser() {
        return this.authenticationService.user.usuario;
    }
    /**
     * ?obtener el valor de id usuario de la BD
     */
    getIdUser() {
        return this.authenticationService.user.id;
    }
    getUserRole() {
        return this.authenticationService.user.rol.descripcion;
    }
    getUserLegajo() {
        return this.authenticationService.user.legajo;
    }
    getUserName() {
        return this.authenticationService.user.nombre;
    }
    obtenerNotificaciones() {
        return this.httpClient.get(`notificaciones`);
    }
    actualizarNotificaciones() {
        return this.httpClient.get(`notificaciones/actualizar`);
    }
    obtenerCantidadNotificaciones() {
        return this.httpClient.get(`notificaciones/count`);
    }
    obtenerRol() {
        return this.authenticationService.user.rol.descripcion;
    }
    //TODO: cambiar por ids
    esSolicitante() {
        return this.authenticationService.user.rol.descripcion === 'Solicitante';
    }
    esAdministrador() {
        return this.authenticationService.user.rol.descripcion === 'Administrador';
    }
    esResolutor() {
        return this.authenticationService.user.rol.descripcion === 'Resolutor';
    }
    esLogistica() {
        return this.authenticationService.user.rol.descripcion === 'Logistica';
    }
    esCoordinadorLogistica() {
        return this.authenticationService.user.rol.descripcion === 'Coordinador de Logistica';
    }
    esChofer() {
        return this.authenticationService.user.rol.descripcion === 'Chofer';
    }
    esSupervisor() {
        return this.authenticationService.user.rol.descripcion === 'Supervisor';
    }
    esSupervisorRiego() {
        return this.authenticationService.user.rol.descripcion === 'Supervisor de Riego';
    }
    obtenerLegajo() {
        return this.authenticationService.user.legajo;
    }
    verificarUser(token, email) {
        return this.httpClient.post(`usuarios/userByEmail`, { token, email });
    }
}
UsuarioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsuarioService_Factory() { return new UsuarioService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.AuthenticationService)); }, token: UsuarioService, providedIn: "root" });
