import * as tslib_1 from "tslib";
import { HttpService } from "../http/http.service";
import { HttpResponse, } from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { MatSnackBar } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import * as i0 from "@angular/core";
import * as i1 from "../http/http.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/authentication/authentication.service";
import * as i4 from "@angular/material/snack-bar";
import * as i5 from "@angular/material/dialog";
/**
 * Interceptor para manejar solicitudes y respuestas
 */
export class InterceptorService {
    constructor(api, router, authService, toastController, dialog) {
        this.api = api;
        this.router = router;
        this.authService = authService;
        this.toastController = toastController;
        this.dialog = dialog;
        this.secondTry = false;
        this.secondTry = false;
    }
    refreshToken(req, next) {
        return this.authService.refreshToken().pipe(switchMap((res) => {
            this.secondTry = false;
            this.authService.updateTokens(res);
            const headers = this.api.getHeaders();
            const newRequest = req.clone({ headers });
            return next.handle(newRequest);
        }));
    }
    presentToast(message, duration = 6000) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.toastController.open(message, "Entiendo", {
                duration: 3000,
                verticalPosition: "bottom",
                horizontalPosition: "center",
            });
        });
    }
    intercept(req, next) {
        return next.handle(req).pipe(tap((event) => {
            if (event instanceof HttpResponse && event.status === 200) {
                if (event.body.redirect == "true") {
                    this.dialog.closeAll();
                    this.router.navigate(["solicitudes"]);
                }
            }
        }), catchError((err) => {
            // No connection.
            if (err.status === 0) {
                this.dialog.closeAll();
                this.router.navigate(["mantenimiento"]);
                // this.presentToast('Error al conectarse con el servidor.');
                // return throwError(err);
            }
            // Unauthorized.
            if (err.status === 401 && this.router.url !== "/login") {
                if (!this.secondTry) {
                    this.secondTry = true;
                    return this.refreshToken(req, next);
                }
                this.presentToast("Su sesión há caducado.");
                this.authService.logOut();
                return throwError(err);
            }
            // Forbidden.
            if (err.status === 403) {
                this.presentToast("No tiene permisos para realizar esta acción.");
                return throwError(err);
            }
            // Server Error
            if (err.status === 500) {
                // Error de conexión con la base de datos
                if (typeof err.error === "string" &&
                    err.error.indexOf("ECONNREFUSED") !== -1) {
                    this.dialog.closeAll();
                    this.router.navigate(["mantenimiento"]);
                }
                else {
                    this.presentToast("Ocurrió un error en el servidor. Contacte a un Administador.");
                    return throwError(err);
                }
            }
            // Server Not Available
            if (err.status === 503) {
                this.dialog.closeAll();
                this.router.navigate(["mantenimiento"]);
                // this.presentToast('El servidor se encuentra caído. Contacte a un Administador.');
                // return throwError(err);
            }
            return throwError(err);
        }));
    }
}
InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.MatSnackBar), i0.ɵɵinject(i5.MatDialog)); }, token: InterceptorService, providedIn: "root" });
