import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/authentication/authentication.service";
import * as i2 from "@angular/router";
/**
 * Guard logueado
 */
export class LoggedInGuard {
    constructor(authenticationService, router) {
        this.authenticationService = authenticationService;
        this.router = router;
    }
    canActivate(next, state) {
        return this.checkUserIsLoggedIn();
    }
    checkUserIsLoggedIn() {
        if (this.authenticationService.isLoggedIn()) {
            return true;
        }
        this.router.navigateByUrl('/login');
        return false;
    }
}
LoggedInGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInGuard_Factory() { return new LoggedInGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: LoggedInGuard, providedIn: "root" });
