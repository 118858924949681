import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario/usuario.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/usuario/usuario.service";
import * as i2 from "@angular/router";
/**
 * Guard usuarios de Riego
 */
export class RiegoUsersGuard {
    constructor(usuarioService, router) {
        this.usuarioService = usuarioService;
        this.router = router;
    }
    canActivate(next, state) {
        return this.canAccessRiegoModule();
    }
    canAccessRiegoModule() {
        const allowedRoles = ['Regador', 'Supervisor de Riego'];
        if (allowedRoles.indexOf(this.usuarioService.getUserRole()) !== -1) {
            return true;
        }
        this.router.navigateByUrl('/solicitudes');
        return false;
    }
}
RiegoUsersGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RiegoUsersGuard_Factory() { return new RiegoUsersGuard(i0.ɵɵinject(i1.UsuarioService), i0.ɵɵinject(i2.Router)); }, token: RiegoUsersGuard, providedIn: "root" });
