import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Clase para realizar queries http con headers y métodos.
 */
export class HttpService {
    /**
     * Creates a new HttpApiService instance and initializes it.
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiUrl = environment.BASE_URL;
        this.setDefaultHeaders();
    }
    /**
     * Sets the default headers and values for all requests made
     * through this service.
     */
    setDefaultHeaders() {
        this.httpHeaders = new HttpHeaders();
        this.httpHeaders
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
    }
    /**
     * Returns all headers and values currently beign used by the service.
     */
    getHeaders() {
        return this.httpHeaders;
    }
    /**
     * Sets a header value for a given name. If the header name already
     * exists, its value is replaced with the given value.
     *
     * @param headerName The header name.
     * @param headerValue Provides the value to set or overide for a given name.
     */
    setHeader(headerName, headerValue) {
        this.httpHeaders = this.httpHeaders.set(headerName, headerValue);
    }
    /**
     * Checks for existence of a header by a given name. Then deletes all
     * header values for the given name, or logs an error in development mode.
     *
     * @param headerName The header name.
     */
    deleteHeader(headerName) {
        if (this.httpHeaders.has(headerName)) {
            this.httpHeaders = this.httpHeaders.delete(headerName);
        }
        else {
            console.warn('Tried to delete a header that does not exist.');
        }
    }
    /**
     * Constructs a `GET` request that interprets the body as a JSON object and
     * returns the response body as a JSON object.
     *
     * @param url The endpoint URL.
     * @param params The HTTP GET parameters.
     *
     * @return An `Observable` of the response body as a JSON object.
     */
    get(url, params) {
        const opts = {
            headers: this.httpHeaders,
            params: null,
        };
        if (params) {
            opts.params = params;
        }
        return this.httpClient.get(this.apiUrl + url, opts);
    }
    /**
     * Constructs a `POST` request that interprets the body as a
     * JSON object and returns the response body as a JSON object.
     *
     * @param url The endpoint URL.
     * @param body The content to replace with.
     *
     * @return An `Observable` of the response, with the response body as a JSON object.
     */
    post(url, body) {
        const opts = {
            headers: this.httpHeaders,
        };
        if (!body) {
            // tslint:disable-next-line: no-parameter-reassignment
            body = {};
        }
        return this.httpClient.post(this.apiUrl + url, body, opts);
    }
    /**
     * Constructs a `PUT` request that interprets the body as a JSON object and
     * returns the response body as a JSON object.
     *
     * @param url The endpoint URL.
     * @param body The resources to add/update.
     *
     * @return An `Observable` of the response, with the response body as a JSON object.
     */
    put(url, body) {
        const opts = {
            headers: this.httpHeaders,
        };
        if (!body) {
            // tslint:disable-next-line: no-parameter-reassignment
            body = {};
        }
        return this.httpClient.put(this.apiUrl + url, body, opts);
    }
    /**
     * Constructs a `DELETE` request that interprets the body as a JSON object and
     * returns the response body as a JSON object.
     *
     * @param url The endpoint URL.
     *
     * @return An `Observable` of the response, with the response body of type `Object`.
     */
    delete(url) {
        const opts = {
            headers: this.httpHeaders,
        };
        return this.httpClient.delete(this.apiUrl + url, opts);
    }
    // Legacy Support.
    agregarFiltrosParams(filtros, httpParams) {
        let params = httpParams;
        for (const filtro in filtros) {
            if (filtros.hasOwnProperty(filtro)) {
                params = params.append(filtro, filtros[filtro]);
            }
        }
        return params;
    }
    // File support.
    download(url, params) {
        const opts = {
            params: null,
            // https://github.com/angular/angular/issues/18586#issuecomment-321333934
            responseType: 'text',
        };
        if (params) {
            opts.params = params;
        }
        return this.httpClient.get(this.apiUrl + url, opts);
    }
    // File excel support.
    excelDownload(url, params) {
        return this.httpClient.get(this.apiUrl + url, {
            headers: this.httpHeaders,
            responseType: 'blob',
            params
        });
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient)); }, token: HttpService, providedIn: "root" });
